@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
* {
    font-family: 'Poppins', 'Roboto', sans-serif;
}

#root {
    height: 100%;
    background: #FFF;
}

.ant-layout {
    background: #f9f9f9 !important;
}

.ant-input-number {
    width: 100% !important;
}

.sortable {
    z-index: 9000;
}

.ant-layout-header {
    background: #007bff;
    padding: 0 60px;
}

.ant-btn {
    font-size: 13px;
}

.ant-btn-primary {
    background: #17c671;
    border-color: #17c671;
    border-radius: 6px !important;
}

.ant-btn-primary:hover {
    background: #17c671;
    border-color: #17c671;
    box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper {
    border-radius: 4px !important;
}

.ant-modal-content {
    border-radius: 10px;
}

.ant-modal-header {
    border-radius: 10px 10px 0 0;
    border-bottom: 0;
}

.ant-modal-footer {
    border-top: 0;
}

.full-screen {
    width: 100% !important;
    height: 100% !important;
}

.ant-picker {
    border-radius: 6px !important;
}

.ant-card-head {
    border-bottom: 0 !important;
}

.ant-table {
    overflow: auto;
}
